<template>
  <div class="login">
    <img src="../../assets/images/login-bgc.png" alt class="login_bgc" />
    <div class="login_import_input">
      <div class="top-title">BY HULIAN 百应互联</div>
      <div class="login_import_input_head" v-if="!registerVisible">
        <!-- <div class="login_import_input_head_item" @click="handleSwitchLogin(1)">
          <div>验证码登录</div>
          <span :style="loginStatusVisible ? 'background: #409eff' : ''"></span>
        </div>
        <div class="login_import_input_head_item" @click="handleSwitchLogin(2)">
          <div>密码登录</div>
          <span :style="!loginStatusVisible ? 'background: #409eff' : ''"></span>
        </div> -->
        <div class="user_login">用户登录</div>
      </div>
      <div class="login_import_input_phone" v-if="!registerVisible && loginStatusVisible">
        <div class="import_input_password">
          <el-input placeholder="请输入用户名" v-model="account" class="phone_input h-40 import_input fl" :prefix-icon="Phone">
            <i class="input_icon"></i>
          </el-input>
          <!-- <div class="verifycode_img fl" @click="sendVerifyCode">
            <el-button type="primary">发送验证码</el-button>
          </div> -->
        </div>
        <div class="import_input_password">
          <el-input placeholder="请输入密码" v-model="password" show-password class="import_input" :prefix-icon="View">
            <i class="input_icon"></i>
          </el-input>
        </div>
      </div>
      <div class="login_import_input_password" v-if="!registerVisible && !loginStatusVisible">
        <div class="import_input_account">
          <el-input placeholder="请输入手机号" v-model="phone" class="import_input" :prefix-icon="Phone"></el-input>
        </div>
        <div class="import_input_password">
          <el-input placeholder="请输入密码" v-model="password" show-password class="import_input" :prefix-icon="View">
            <i class="input_icon"></i>
          </el-input>
        </div>
      </div>
      <div class="login_import_input_register" v-if="registerVisible">
        <div class="import_input_account">
          <el-input placeholder="请输入用户名" v-model="userName" class="import_input" :prefix-icon="User"></el-input>
        </div>
        <div class="import_input_password">
          <el-input placeholder="请输入手机号" v-model="phone" class="phone_input import_input fl" :prefix-icon="Phone">
            <i class="input_icon"></i>
          </el-input>
          <div class="verifycode_img fl" @click="sendVerifyCode">
            <el-button type="primary">{{ verifyText }}</el-button>
          </div>
        </div>
        <div class="import_input_verifycode clearfix">
          <el-input placeholder="请输入验证码" v-model="verifyCode" class="verifycode_input import_input fl">
            <i class="input_icon"></i>
          </el-input>
        </div>
        <div class="import_input_password">
          <el-input placeholder="请输入密码" v-model="password" show-password class="import_input" :prefix-icon="View">
            <i class="input_icon"></i>
          </el-input>
        </div>
      </div>

      <div class="import_input_btn">
        <el-button class="input_btn" type="primary" size="large" @click="handleBtn">{{ registerVisible ? "注册" : "登录"
        }}</el-button>
        <!-- <div class="import_input_btn-desc" @click="handleRegister">
          还没有账号，
          <span>{{ registerVisible ? "立即登录" : "立即注册" }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>
  
<script>
import { User, View, Phone } from "@element-plus/icons-vue";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { isConformPhone } from "@/utils/utils";
import { userSendVerifyCode } from "@/services/modules/common";
import { userLogin } from "@/services/modules/user";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const registerVisible = ref(false);
    const loginStatusVisible = ref(true);
    const userName = ref("");
    const password = ref("");
    const phone = ref("");
    const verifyCode = ref("");
    const verifyText = ref("发送验证码");
    const verifyTimeoutNum = ref(60);
    const account = ref('')
    let timer = null;
    const handleSwitchLogin = (type) => {
      loginStatusVisible.value = type === 1;
      verifyCode.value = "";
      phone.value = "";
      userName.value = "";
      password.value = "";
    };

    const handleRegister = () => {
      registerVisible.value = !registerVisible.value;
      verifyCode.value = "";
      phone.value = "";
      userName.value = "";
      password.value = "";
    };
    const sendVerifyCode = async () => {
      if (timer) return;
      if (!isConformPhone(phone.value)) {
        return ElMessage.error("请输入正确的手机号");
      }

      const params = {
        phone: phone.value,
      };
      const res = await userSendVerifyCode(params);
      if (res.code == 1) {
        ElMessage.success("发送成功");
        verifyCodeTimeout();
        return;
      }

      ElMessage.error(res.msg || "发送失败");
    };

    const verifyCodeTimeout = () => {
      timer = setInterval(() => {
        if (verifyTimeoutNum.value === 0) {
          timer = null;
          clearInterval(timer);
          verifyTimeoutNum.value = 60;
          verifyText.value = "重新发送";
          return;
        }
        verifyTimeoutNum.value = verifyTimeoutNum.value - 1;
        verifyText.value = `${verifyTimeoutNum.value}s`;
      }, 1000);
    };

    // const userHandleRegister = async () => {
    //   if (!userName.value) {
    //     return ElMessage.error("请输入用户名");
    //   }

    //   if (!password.value) {
    //     return ElMessage.error("请输入密码");
    //   }

    //   if (!isConformPhone(phone.value)) {
    //     return ElMessage.error("请输入正确的手机号");
    //   }

    //   if (!verifyCode.value) {
    //     return ElMessage.error("请输入验证码");
    //   }

    //   const params = {
    //     phone: phone.value,
    //     password: password.value,
    //     code: verifyCode.value,
    //     nickname: userName.value,
    //     identity: 3,
    //   };

    //   const res = await userRegister(params);
    //   if (res.code == 1) {
    //     ElMessage.success("注册成功，请登录");
    //     setTimeout(() => {
    //       registerVisible.value = false;
    //       verifyCode.value = "";
    //       phone.value = "";
    //       userName.value = "";
    //       password.value = "";
    //     }, 1000);
    //     return;
    //   }

    //   ElMessage.error(res.msg || "注册失败，请稍后重试");
    // };

    const userHandleLogin = async () => {
      if (!account.value) {
        return ElMessage.error("请输入账号");
      }

      if (!password.value) {
        return ElMessage.error("请输入密码");
      }

      let params = {
        account: account.value,
        password: password.value,
      };


      const res = await userLogin(params);

      // const res = {
      //     code: 1,
      //     msg: "success",
      //     data: {
      //         userId: null,
      //         nickName: "哈哈哈",
      //         identity: 2,
      //         token: "qolFJRRIkT//1w+R9X3rkA==",
      //         menu: ["创建订单","订单列表","地址管理","充值管理","个人中心","机构管理"]
      //     }
      // };
      if (res.code == 1) {
        window.localStorage.setItem("toothToken", res.data.token);
        window.localStorage.setItem("userInfo", JSON.stringify(res.data));
        ElMessage.success("登录成功");
        router.push("/");
        return;
      }

      ElMessage.error(res.msg || "登录失败，请稍后重试");
    };

    const handleBtn = () => {
      console.log(registerVisible.value);
      // if (registerVisible.value) {
      //   userHandleRegister();
      // } else {
      userHandleLogin();
      // }
    };

    return {
      User,
      View,
      Phone,
      userName,
      password,
      phone,
      account,
      verifyCode,
      verifyText,
      registerVisible,
      loginStatusVisible,
      handleBtn,
      sendVerifyCode,
      handleRegister,
      handleSwitchLogin,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.login {
  height: 100%;
}

.inter_card_login,
.login_bgc {
  position: relative;
  width: 100%;
  height: 100%;
}

.login_import_input_head {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  // justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;

  .user_login {
    font-size: 20px;
  }

  &_item {
    width: max-content;
    text-align: center;

    div {
      font-size: 16px;
      font-weight: bold;
    }

    span {
      display: inline-block;
      width: 50px;
      height: 5px;
    }
  }
}

.top-title {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  color: rgb(107, 152, 255);
}

// 登录框
.login_import_input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 417px;
  height: 370px;
  background: #FFFFFF;
  border-radius: 20px;
  border: 1px solid #EFF1F4;

  // 账号
  .import_input_account {
    .input_icon {
      background: url("../../assets/images/account_number.png") no-repeat;
      background-size: 70% 70%;
    }
  }

  // 密码
  .import_input_password {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .phone_input {
      // width: 60%;
    }

    .input_icon {
      background: url("../../assets/images/password.png") no-repeat;
      background-size: 60% 70%;
    }
  }

  // 验证码
  .import_input_verifycode {
    margin-top: 30px;

    // input输入框
    .verifycode_input {

      // 图标
      .input_icon {
        background: url("../../assets/images/verify_code.png") no-repeat;
        background-size: 60% 70%;
      }
    }

    // 图片验证码
    .verifycode_img {
      margin-left: 5%;
      width: 35%;
      height: 48px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // 登录按钮
  .import_input_btn {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    .input_btn {
      width: 300px;
      height: 50px;
      font-size: 16px;
      background-color: #3572FF;
    }

    &-desc {
      span {
        cursor: pointer;
        color: #409eff;
      }
    }
  }
}

.h-40 {
  height: 40px;
}
</style>
  