function isConformPhone(phone) {
  if (!(/^1[3456789]\d{9}$/.test(phone))) {
    return false;
  } else {
    return true
  }
}

function formatDate(date, fmt = 'yyyy-MM-dd') {
  if (typeof (date) === 'number') {
    date = new Date(date)
  } else { return false }
  var o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    "S": date.getMilliseconds()                  // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
  return fmt
}

function copyText(text) {
  var input = document.createElement('textarea')
  input.setAttribute('id', 'input_for_copyText')
  input.value = text
  document.getElementsByTagName('body')[0].appendChild(input)
  document.getElementById('input_for_copyText').select()
  document.execCommand('copy')
  document.getElementById('input_for_copyText').remove()
}

export {
  isConformPhone,
  formatDate,
  copyText
}